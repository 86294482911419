<template>
  <Switch
    v-model="model"
    :class="model ? 'bg-indigo-500' : 'bg-gray-300'"
    class="relative inline-flex h-7 w-12 items-center rounded-full"
  >
    <span
      :class="model ? 'translate-x-6' : 'translate-x-1'"
      class="bg-white inline-block h-5 w-5 transform rounded-full duration-300 ease-in-out"
    />
  </Switch>
</template>

<script setup>
  import { Switch } from '@headlessui/vue';

  const model = defineModel({
    default: false,
    required: true,
  });
</script>
